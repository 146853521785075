<template>
    <Guest :title="t('page_title')">
        <div class="py-8">
            <form v-on:submit.prevent="handleSubmit">
                <TextInput v-model:value="form.email" v-model:errors="errors.email" :label="t('email')" :placeholder="t('email')" />
                <TextInput v-model:value="form.password" v-model:errors="errors.password" :label="t('password')" :placeholder="t('password')" password class="mt-4" autofocus />
                <TextInput v-model:value="form.password_confirmation" v-model:errors="errors.password_confirmation" :label="t('password_confirmation')" :placeholder="t('password_confirmation')" password class="mt-4" />
                <div class="mt-12">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full">
                            {{ t('reset_password') }}
                        </button>
                    </span>
                </div>
                <div class="mt-6">
                    <router-link :to="{ name: 'login'}">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="submit" class="button-transparent w-full">
                                {{ t('back') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </form>
        </div>
    </Guest>
</template>

<script>
import Guest from '@/wrappers/Guest';
import TextInput from "@/components/forms/TextInput";
import { resetPassword } from "@/helpers/api/PasswordResetHelper";
import messages from "@/helpers/storage/MessageHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "PasswordReset",
    components: { Guest, TextInput },
    setup() {
        return useI18n({
            viewPrefix: "PasswordReset"
        });
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                token: '',
            },
            errors: {
                email: [],
                password: [],
                password_confirmation: [],
                token: [],
            }
        }
    },
    methods: {
        handleSubmit(){
            setLoading(true);

            resetPassword(this.form)
                .then(() => {
                    messages.success(this.t('messages.password_is_reset'));
                    this.$router.push("/login");
                })
                .catch(error => {
                    messages.error(this.t('messages.error'));
                    this.errors = error.errors;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
    created: function() {
        // add the token from the route to the component data
        this.form.token = this.$route.params.token;
        this.form.email = this.$route.query.email;
    },
}
</script>